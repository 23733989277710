<template>
  <div>
    <el-form :model="detailData" label-suffix=":" label-width="150px">
      <div class="title">1.发票信息</div>
      <template v-if="detailData.invoice">
        <el-form-item label="企业详细地址" :rules="[{ required: true }]">
          <!-- {{ detailData.invoice.taxAddress }} -->
          {{ detailData.postInfo.detailAddress }}
        </el-form-item>
        <!-- <el-form-item label="增值税一般纳税人" :rules="[{ required: true }]">
          {{ detailData.invoice.taxNature == 1 ? "是" : "否" }}
        </el-form-item>
        <el-form-item label="发票抬头" :rules="[{ required: true }]">
          {{ detailData.invoice.invoice }}
        </el-form-item>
        <el-form-item label="纳税人识别号" :rules="[{ required: true }]">
          {{ detailData.invoice.taxId }}
        </el-form-item>
        <el-form-item label="税务注册地址" :rules="[{ required: true }]">
          {{ detailData.invoice.taxAddress }}
        </el-form-item>
        <el-form-item label="注册电话" :rules="[{ required: true }]">
          {{ detailData.invoice.registerTel }}
        </el-form-item>
        <el-form-item label="开户行" :rules="[{ required: true }]">
          {{ detailData.invoice.bank }}
        </el-form-item>
        <el-form-item label="银行账号" :rules="[{ required: true }]">
          {{ detailData.invoice.bankAccount }}
        </el-form-item> -->
      </template>
      <div class="title">2.接收信息</div>
      <template v-if="detailData.postInfo">
        <!-- <el-form-item label="发票邮寄地址" :rules="[{ required: true }]">
          {{ detailData.postInfo.detailAddress || detailData.postInfo.address }}
        </el-form-item> -->
        <el-form-item label="电邮地址">
          {{ detailData.postInfo.email }}
        </el-form-item>
        <el-form-item label="联系人姓名" :rules="[{ required: true }]">
          {{ detailData.postInfo.name }}
        </el-form-item>
        <el-form-item label="联系人手机号" :rules="[{ required: true }]">
          {{ detailData.postInfo.phoneNumber }}
        </el-form-item>

        <el-form-item label="经办人" :rules="[{ required: true }]">
          {{ detailData.manager }}
          <i
            v-if="detailData.manager"
            class="el-icon-document-copy myCopy"
            @click="copyData(detailData.manager)"
          ></i>
        </el-form-item>
        <el-form-item label="联系方式" :rules="[{ required: true }]">
          {{ detailData.connectNumber }}
          <i
            v-if="detailData.connectNumber"
            class="el-icon-document-copy myCopy"
            @click="copyData(detailData.connectNumber)"
          ></i>
        </el-form-item>
        <el-form-item label="备注" :rules="[{ required: false }]">
          {{ detailData.remarks }}
        </el-form-item>
        <div class="downloadArea">
          <!--线上签约的合同模板 -->
          <template v-if="detailData.contractType == 1 && showContractPdf">
            <ContractPdf
              :detailData="detailData"
              ref="contractRef"
            ></ContractPdf>
          </template>
          <!-- 商务对接后已签署的合同文件 -->
          <template v-if="detailData.contractType == 2 && detailData.scanFile">
            <el-button type="primary" @click="signedDownload()">
              下载合同
            </el-button>
          </template>
        </div>
      </template>
    </el-form>
  </div>
</template>
<script>
import { downloadFile } from "@/common/utils/utils.js";
import ContractPdf from "./ContractPdf";
export default {
  components: { ContractPdf },
  props: {
    detailData: {
      type: Object,
      default() {
        return { postInfo: {}, invoice: {}, userInfo: {} };
      },
    },
    // 是否显示线上签约的合同模板
    showContractPdf: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {};
  },
  methods: {
    handleDownload() {
      this.$refs.contractRef.exportPdf();
    },
    signedDownload() {
      const { scanFile } = this.detailData;
      if (scanFile) {
        downloadFile(scanFile);
      } else {
        this.$message.error("此合同不存在");
      }
    },
    copyData(value) {
      const input = document.createElement("input"); // 创建input对象
      input.value = value; // 设置复制内容
      document.body.appendChild(input); // 添加临时实例
      input.select(); // 选择实例内容
      document.execCommand("Copy"); // 执行复制
      document.body.removeChild(input); // 删除临时实例
      this.$message.success("复制成功！");
    },
  },
};
</script>
<style scoped>
.title {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 10px;
}
.myCopy {
  font-size: 20px;
  margin-left: 5px;
  cursor: pointer;
}
.downloadArea {
  text-align: center;
}
</style>
