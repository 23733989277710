<template>
  <el-form
    class="auditModel"
    ref="formRef"
    :model="formData"
    :rules="rules"
    label-width="170px"
    label-suffix="："
  >
    <el-form-item label="审核结果" prop="auditStatus">
      <el-radio-group
        v-model="formData.auditStatus"
        @change="$refs.formRef.clearValidate()"
      >
        <el-radio :label="0">通过</el-radio>
        <el-radio :label="1">不通过</el-radio>
      </el-radio-group>
    </el-form-item>
    <el-form-item
      label="合同开始时间"
      :prop="detailData.auditStatus == 2 ? 'contractStartTime' : ''"
      v-if="detailData.auditStatus == 2 && formData.auditStatus != 1"
    >
      <el-date-picker
        v-model="formData.contractStartTime"
        type="date"
        value-format="yyyy-MM-dd HH:mm:ss"
        placeholder="选择合同开始时间"
      >
      </el-date-picker>
    </el-form-item>
    <el-form-item
      label="合同结束时间"
      :prop="detailData.auditStatus == 2 ? 'contractEndTime' : ''"
      v-if="detailData.auditStatus == 2 && formData.auditStatus != 1"
    >
      <el-date-picker
        v-model="formData.contractEndTime"
        type="date"
        value-format="yyyy-MM-dd HH:mm:ss"
        placeholder="选择合同结束时间"
      >
      </el-date-picker>
    </el-form-item>
    <!-- 线下签约 协议状态为审核通过待签署 审核结果选择通过时显示 3.29 p1级新需求去除发票信息和邮寄信息的填写  -->
    <template
      v-if="
        detailData.contractType == 2 &&
        detailData.auditStatus == 2 &&
        formData.auditStatus == 0 &&
        false
      "
    >
      <el-form-item label="线下合同扫描件上传" prop="scanFile">
        <UploadFile
          type="other"
          class="ml10"
          ref="uploadRef"
          :acceptType="['pdf']"
          @success="uploadSuccess"
          v-model="formData.scanFile"
        ></UploadFile>
        <div class="ml10" v-show="formData.fileName">
          <span>{{ formData.fileName }}</span>
          <i class="el-icon-close cursor ml10" @click="handleRemoveFile()"></i>
        </div>
      </el-form-item>
      <div class="title">发票信息</div>
      <el-form-item label="企业详细地址" required>
        <citySelection
          :modelValue="formData.invoice"
          v-model="formData.invoice"
        ></citySelection>
      </el-form-item>
      <!-- <el-form-item label="增值税一般纳税人" prop="invoice.taxNature">
        <el-radio-group v-model="formData.invoice.taxNature">
          <el-radio :label="1">是</el-radio>
          <el-radio :label="2">否</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="发票抬头" prop="invoice.invoice">
        <el-input
          v-model="formData.invoice.invoice"
          placeholder="请输入发票抬头"
        >
        </el-input>
      </el-form-item>
      <el-form-item label="纳税人识别号" prop="invoice.taxId">
        <el-input
          v-model="formData.invoice.taxId"
          placeholder="请输入纳税人识别号"
        >
        </el-input>
      </el-form-item>
      <el-form-item label="纳税人税务注册地址" required>
        <citySelection
          :modelValue="formData.invoice"
          v-model="formData.invoice"
        ></citySelection>
      </el-form-item>
      <el-form-item label="注册电话" prop="invoice.registerTel">
        <el-input
          placeholder="请输入注册电话"
          v-model="formData.invoice.registerTel"
        ></el-input>
      </el-form-item>
      <el-form-item label="开户行" prop="invoice.bank">
        <el-input
          placeholder="请输入开户行"
          v-model="formData.invoice.bank"
        ></el-input>
      </el-form-item>
      <el-form-item label="银行账号" prop="invoice.bankAccount">
        <el-input
          placeholder="请输入银行账号"
          v-model="formData.invoice.bankAccount"
        ></el-input>
      </el-form-item> -->
      <div class="title">邮寄信息</div>
      <el-form-item label="发票邮寄地址" prop="postInfo.address">
        <el-input
          placeholder="请输入发票邮寄地址"
          v-model="formData.postInfo.address"
        ></el-input>
      </el-form-item>
      <el-form-item label="邮寄联系人姓名" prop="postInfo.name">
        <el-input
          placeholder="请输入联系人姓名"
          v-model="formData.postInfo.name"
        ></el-input>
      </el-form-item>
      <el-form-item label="电子邮件" prop="postInfo.email">
        <el-input
          placeholder="请输入联系人邮箱"
          v-model="formData.postInfo.email"
        ></el-input>
      </el-form-item>
      <!-- <el-form-item label="经办人手机号" prop="postInfo.phoneNumber">
        <el-input
          placeholder="请输入经办人手机号"
          v-model="formData.postInfo.phoneNumber"
        ></el-input>
      </el-form-item> -->
      <el-form-item> </el-form-item>
    </template>
    <template v-else>
      <el-form-item
        label="审核意见"
        prop="auditView"
        :rules="[
          { required: formData.auditStatus == 1, message: '请输入审核意见' },
        ]"
      >
        <el-input
          type="textarea"
          :autosize="{ minRows: 6, maxRows: 6 }"
          show-word-limit
          maxlength="100"
          v-model="formData.auditView"
          suffix-icon="el-icon-date"
          autocomplete="off"
        ></el-input>
      </el-form-item>
      <el-form-item label="备注" prop="remarks">
        <el-input
          type="textarea"
          :autosize="{ minRows: 3, maxRows: 3 }"
          show-word-limit
          maxlength="100"
          v-model="formData.remarks"
          suffix-icon="el-icon-date"
          autocomplete="off"
        ></el-input>
      </el-form-item>
      <el-form-item label="文件上传">
        <UploadFile
          type="other"
          class="ml10"
          ref="uploadRef"
          @success="uploadSuccess"
          v-model="formData.filePath"
        ></UploadFile>
        <div class="ml10" v-show="formData.fileName">
          <span>{{ formData.fileName }}</span>
          <i class="el-icon-close cursor ml10" @click="handleRemoveFile()"></i>
        </div>
      </el-form-item>
    </template>
    <el-form-item>
      <el-button size="small" type="primary" @click="handleSubmitAudit"
        >提交</el-button
      >
    </el-form-item>
  </el-form>
</template>

<script>
import UploadFile from "@/components/UploadFile1.vue";
import {
  checkPhone,
  checkPhoneTel,
  checkEmail,
  compareDates,
} from "@/common/utils/utils.js";
import citySelection from "@/components/citySelection.vue";
export default {
  components: { UploadFile, citySelection },
  props: {
    detailData: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      compareDates,
      formData: {
        fileName: "",
        scanFile: "",
        invoice: {
          taxProvince: "",
          taxCity: "",
          taxCountry: "",
          province: "",
          city: "",
          county: "",
        },
        postInfo: {
          phoneNumber: "",
          email: "",
          name1: "",
          name: "",
          address: "",
        },
        pickerOptions: {
          disabledDate(date) {
            return date && date < new Date();
          },
        },
      },
      rules: {
        auditStatus: [{ required: true, message: "请选择审核结果" }],
        contractStartTime: [{ required: true, message: "请选择合同开始时间" }],
        contractEndTime: [{ required: true, message: "请选择合同结束时间" }],
        scanFile: [{ required: true, message: "请上传线下合同扫描件" }],
        "invoice.taxNature": [
          { required: true, message: "请选择增值税一般纳税人" },
        ],

        "invoice.invoice": [{ required: true, message: "请输入发票抬头" }],
        "invoice.taxId": [{ required: true, message: "请输入纳税人识别号" }],
        "invoice.bankAccount": [{ required: true, message: "请输入银行账号" }],
        "invoice.bank": [{ required: true, message: "请输入开户行" }],
        "invoice.registerTel": [
          { required: true, message: "请输入注册电话" },
          { validator: checkPhoneTel },
        ],
        "postInfo.address": [{ required: true, message: "请输入邮寄地址" }],
        "postInfo.name": [{ required: true, message: "请输入联系人姓名" }],
        "postInfo.name1": [{ required: true, message: "请输入经办人" }],
        "postInfo.email": [
          { required: true, message: "请输入联系人邮箱" },
          { validator: checkEmail },
        ],
        "postInfo.phoneNumber": [
          { required: true, message: "请输入经办人手机号" },
          { validator: checkPhone },
        ],
      },
    };
  },
  methods: {
    uploadSuccess(file) {
      if (
        this.detailData.contractType == 2 &&
        this.detailData.auditStatus == 2 &&
        this.formData.auditStatus == 0
      ) {
        this.formData.scanFile = file.accessPath;
        this.$refs.formRef.clearValidate("scanFile");
      } else {
        this.formData.filePath = file.accessPath;
      }
      this.formData.fileName = file.fileName;
    },
    handleRemoveFile() {
      this.formData.scanFile = "";
      this.formData.filePath = "";
      this.formData.fileName = "";
    },
    handleSubmitAudit() {
      this.$refs.formRef.validate((valid) => {
        const uploadValid = this.$refs.uploadRef.getValidate();
        if (valid && uploadValid) {
          this.formData.invoice.taxProvince = this.formData.invoice.province;
          this.formData.invoice.taxCity = this.formData.invoice.city;
          this.formData.invoice.taxCountry = this.formData.invoice.county;
          if (
            this.detailData.contractType == 2 &&
            this.detailData.auditStatus == 2 &&
            this.formData.auditStatus == 0
          ) {
            this.formData.submitType = 2;
          } else {
            this.formData.submitType = 1;
          }
          if (
            this.compareDates(
              this.formData.contractStartTime,
              this.formData.contractEndTime
            ) >= 0 &&
            this.formData.auditStatus == 0 &&
            this.detailData.auditStatus == 2
          ) {
            this.$message.error("合同开始时间要小于合同结束时间");
            return;
          }
          this.$emit("submit", this.formData);
        }
      });
    },
  },
};
</script>

<style lang="scss">
.auditModel {
  width: 67%;
  margin: 0 auto;

  .title {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 10px;
  }
  .el-input,
  .el-textarea {
    display: inline-block;
    // width: 300px;
  }
  .formItemRemark {
    font-size: 12px;
    // width:700px;
  }
  .el-input--prefix .el-input__inner {
    padding-left: 12px;
    padding-right: 12px;
  }
  .el-date-editor .el-input__prefix {
    left: auto;
    right: 5px;
  }
}
</style>
