<template>
  <div>
    <div>
      <el-row :gutter="6">
        <el-col :span="8">
          <el-input placeholder="请输入开发者" v-model="searchData.userName">
          </el-input>
        </el-col>
        <el-col :span="4">
          <el-select
            style="width: 100%"
            clearable
            filterable
            v-model="searchData.contractType"
            placeholder="签约类型"
          >
            <el-option
              v-for="item in contractTypeList"
              :key="item.key"
              :value="item.key"
              :label="item.value"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="4">
          <el-select
            style="width: 100%"
            clearable
            filterable
            v-model="searchData.auditStatus"
            placeholder="审核状态"
          >
            <el-option
              v-for="item in contractAuditList"
              :key="item.key"
              :value="item.key"
              :label="item.value"
            >
            </el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-row :gutter="6">
        <el-col :span="8">
          <el-row type="flex" align="middle">
            <span style="white-space: nowrap"> 提交日期：</span>
            <el-date-picker
              type="daterange"
              range-separator="至"
              v-model="searchData.date"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-row>
        </el-col>
        <el-col :span="4">
          <el-button
            type="primary"
            icon="el-icon-search"
            @click="handleSearch()"
            >查询</el-button
          >
        </el-col>
      </el-row>
      <PageTable
        rowKey="releaseId"
        :columns="columns"
        :tableData="tableData"
        @page-change="handleChangePage"
      >
        <template slot-scope="{ row }" slot="auditStatus">
          <el-tag :type="row.auditStatus | tagType(contractAuditList)">
            {{ row.auditStatus | typeDesc(contractAuditList) }}
          </el-tag>
        </template>
        <template slot-scope="{ row }" slot="contractType">
          {{ row.contractType | typeDesc(contractTypeList) }}
        </template>
        <template slot-scope="{ row }" slot="contractStatus">
          <el-tag :type="row.contractStatus | tagType(contractStatusList)">
            {{ row.contractStatus | typeDesc(contractStatusList) }}
          </el-tag>
        </template>
        <template slot-scope="{ row }" slot="contractStartTime">
          <span v-if="row.auditStatus == 6">{{ row.contractStartTime }}</span>
        </template>
        <template slot-scope="{ row }" slot="contractEndTime">
          <span v-if="row.auditStatus == 6">{{ row.contractEndTime }}</span>
        </template>
        <el-table-column
          label="操作"
          width="130px"
          align="center"
          fixed="right"
        >
          <template slot-scope="{ row }">
            <el-button
              v-has="'lookinfo'"
              type="text"
              @click="handleViewDetail(row)"
            >
              <span class="colorGreen">查看</span>
            </el-button>
            <el-button
              v-has="'review'"
              class="ml10"
              type="text"
              :disabled="!reviewList.includes(row.auditStatus)"
              @click="handleReview(row)"
            >
              <span>审核</span>
            </el-button>
          </template>
        </el-table-column>
      </PageTable>
    </div>
    <el-dialog
      center
      destroy-on-close
      :close-on-click-modal="false"
      :title="detailData.auditStatus == 2 ? '签署审核' : '签约审核'"
      :visible.sync="dialogAuditVisible"
    >
      <auditModel
        :detailData="detailData"
        @submit="handleSubmitAudit"
      ></auditModel>
    </el-dialog>
    <el-dialog
      center
      destroy-on-close
      :close-on-click-modal="false"
      title="合同详情"
      :visible.sync="dialogDetailVisible"
    >
      <Detail :detailData="contractDetail"></Detail>
    </el-dialog>
  </div>
</template>
<script>
import Detail from "./Detail.vue";
import auditModel from "./auditModel.vue";
import {
  contractAuditList,
  contractTypeList,
  contractStatusList,
} from "@/common/constant/constant.js";
import {
  contractAudit,
  getContractList,
  checkContractInfo,
  addContractInfo,
} from "./indexServe";
import PageTable from "@/components/PageTableSort";
import moment from "moment";
export default {
  name: "contractManage",
  components: { auditModel, Detail, PageTable },
  mounted() {
    this.init();
  },
  data() {
    let end = moment(new Date()).format("YYYY-MM-DD");
    let start = new Date();
    start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
    start = moment(start).format("YYYY-MM-DD");
    return {
      contractAuditList,
      contractTypeList,
      contractStatusList,
      searchData: {
        date: [start, end],
      },
      tableData: {
        list: [],
      },
      columns: [
        {
          key: "userName",
          label: "开发者",
        },
        {
          slot: "contractType",
          label: "签约类型",
        },
        {
          key: "submitTime",
          label: "提交时间",
        },
        {
          slot: "contractStartTime",
          label: "合同开始时间",
        },
        {
          slot: "contractEndTime",
          label: "合同结束时间",
        },
        {
          key: "auditTime",
          label: "审核时间",
        },
        {
          slot: "auditStatus",
          label: "协议审核状态",
        },
        {
          slot: "contractStatus",
          label: "合同状态",
        },
        {
          key: "businessName",
          label: "商务对接人",
        },
        {
          key: "resolveUserName",
          label: "审核人员",
        },
        {
          key: "auditView",
          label: "审核意见",
        },
      ],
      detailData: {},
      contractDetail: { invoice: {}, postInfo: {}, userInfo: {} },
      dialogAuditVisible: false,
      dialogDetailVisible: false,
      reviewList: [1, 2], // 可审核的状态列表
    };
  },
  methods: {
    // 事件监听
    handleChangePage({ page, size }) {
      this.getTableList({ page, size });
    },

    handleSearch() {
      this.getTableList({ page: 1 });
    },
    handleViewDetail(row) {
      this.dialogDetailVisible = true;
      this.checkContractInfo({ id: row.id, contractType: row.contractType });
    },
    handleReview(row) {
      this.detailData = { ...row };
      this.dialogAuditVisible = true;
    },
    handleSubmitAudit(formData) {
      const { auditStatus, id } = this.detailData;
      // auditType审核类型：0签约审核，1签署审核 2运营审核
      const data = { id, auditType: 0, ...formData };
      if (auditStatus == 2) {
        data.auditType = 1; //签署审核
      }
      if (formData.submitType == 1) {
        this.contractAudit(data);
      } else if (formData.submitType == 2) {
        // 商务对接，签署合同时，先保存合同信息，再调用审核通过接口
        addContractInfo(data).then((res) => {
          if (res && res.code === 0) {
            this.contractAudit(data);
          }
        });
      }
    },
    // 后端请求
    // 获取列表
    async getTableList(params) {
      const { date, auditStatus, ...rest } = this.searchData;
      const _params = {
        page: 1,
        size: 10,
        auditStatus,
        ...rest,
        ...params,
      };
      if (date) {
        _params.submitStart = date[0];
        _params.submitEnd = date[1];
      }
      if (!auditStatus && auditStatus !== 0) {
        _params.auditStatus = -1; // 没选择状态时，-1查询全部
      }
      _params.pageIndex = _params.page;
      _params.pageSize = _params.size;
      if (!_params.contractType) {
        _params.contractType = 0; //没写签约类型时给后端传0
      }
      const res = await getContractList(_params);
      if (res && res.code === 0) {
        const tableData = {
          list: res.data.contractList || [],
          page: _params.page,
          size: _params.size,
          total: res.data.count,
        };
        this.tableData = tableData;
      }
    },
    async checkContractInfo(params) {
      const res = await checkContractInfo(params);
      if (res && res.code === 0) {
        const { onLine = {}, ...rest } = res.data;
        const { invoice = {}, postInfo = {}, userInfo = {} } = onLine;
        const data = { ...rest, invoice, postInfo, userInfo };
        this.contractDetail = data;
      }
    },
    // 审核接口
    async contractAudit(params) {
      const res = await contractAudit(params);
      if (res && res.code === 0) {
        this.dialogAuditVisible = false;
        this.getTableList({
          page: this.tableData.page,
          size: this.tableData.size,
        }); // 成功后刷新列表
        this.$message.success("操作成功");
      }
    },
    // 其他
    init() {
      this.getTableList();
    },
  },
};
</script>
