<template>
  <div class="citySelection">
    <el-row :gutter="5">
      <el-col :span="7">
        <el-form-item prop="province">
          <el-select
            filterable
            v-model="formCustom.province"
            class="m-2"
            placeholder="请选择省"
            @change="provinceChange"
          >
            <el-option
              v-for="item in provinceList"
              :key="item.code"
              :label="item.province"
              :value="item.code"
            />
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="7">
        <el-form-item prop="city">
          <el-select
            filterable
            v-model="formCustom.city"
            class="m-2"
            placeholder="请选择市"
            @change="cityChange"
          >
            <el-option
              v-for="item in cityList"
              :key="item.code"
              :label="item.city"
              :value="item.code"
            />
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="7">
        <el-form-item prop="county">
          <el-select
            filterable
            v-model="formCustom.county"
            class="m-2"
            placeholder="请选择区"
            @change="countyChange"
          >
            <el-option
              v-for="item in districtList"
              :key="item.code"
              :label="item.district"
              :value="item.code"
            />
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { getCityList } from "@/common/js/api";
export default {
  name: "citySelection",
  props: ["modelValue"],
  emits: ["update:modelValue"],
  data() {
    return {
      districtList: [],
      provinceList: [],
      cityList: [],
      formCustom: {
        province: "",
        city: "",
        county: "",
      },
    };
  },
  mounted() {
    this.formCustom = this.modelValue;
    this.initAddress();
  },
  methods: {
    async initAddress() {
      this.cityList = [];
      this.districtList = [];
      let res = await getCityList({
        parentId: "",
      });
      if (res) {
        this.provinceList = res.data;
        // 省级下的市
        if (
          this.formCustom.province &&
          ["110000", "120000", "310000", "500000"].indexOf(
            this.formCustom.province
          ) < 0
        ) {
          let parentId = this.provinceList.find(
            (a) => a.code === this.formCustom.province
          ).id;
          let resCity = await getCityList({
            parentId: parentId,
          });
          if (resCity) {
            this.cityList = resCity.data;
          }
        } else if (
          this.formCustom.province &&
          ["110000", "120000", "310000", "500000"].indexOf(
            this.formCustom.province
          ) >= 0
        ) {
          this.provinceList.forEach((item) => {
            if (this.formCustom.province == item.code) {
              this.cityList.push(item);
            }
          });
        }
        // 市级下的区
        if (this.formCustom.city) {
          let parentId = this.cityList.find(
            (a) => a.code === this.formCustom.city
          ).id;
          let resdistrictList = await getCityList({
            parentId: parentId,
          });
          if (resdistrictList) {
            this.districtList = resdistrictList.data;
          }
        }
      }
    },
    provinceChange(val) {
      this.formCustom.city = "";
      this.formCustom.county = "";
      this.cityList = [];
      if (["110000", "120000", "310000", "500000"].indexOf(val) >= 0) {
        this.provinceList.forEach((item) => {
          if (val == item.code) {
            this.cityList.push(item);
          }
        });
      } else {
        let parentId = this.provinceList.find((a) => a.code === val).id;
        getCityList({
          parentId: parentId,
        }).then((res) => {
          if (res) {
            this.cityList = res.data;
          }
        });
      }
    },
    async cityChange(val) {
      let parentId = this.cityList.find((a) => a.code === val).id;
      this.formCustom.county = "";
      let res = await getCityList({
        parentId: parentId,
      });
      if (res) {
        this.districtList = res.data;
      }
    },
    countyChange() {
      this.submit();
    },
    filterAddress(obj) {
      let provinceCode = "";
      let cityCode = "";
      let districtCode = "";
      let provinceName = "";
      let cityName = "";
      let districtName = "";
      this.provinceList.forEach((item) => {
        if (obj.province === item.code) {
          provinceCode = item.code;
          provinceName = item.province;
        }
      });
      this.cityList.forEach((item) => {
        if (obj.city === item.code) {
          cityCode = item.code;
          cityName = item.city;
        }
      });
      this.districtList.forEach((item) => {
        if (obj.county === item.code) {
          districtCode = item.code;
          districtName = item.district;
        }
      });
      obj.province = provinceCode;
      obj.city = cityCode;
      obj.county = districtCode;
      obj.provinceName = provinceName;
      obj.cityName = cityName;
      obj.districtName = districtName;
    },
    submit() {
      let form = { ...this.formCustom };
      this.filterAddress(form);
      this.$emit("update:modelValue", form);
    },
  },
};
</script>

<style></style>
