<template>
  <div class="pdfWrap">
    <el-button type="primary" @click="exportPdf" :loading="loading"
      >下载合同</el-button
    >
    <div id="pdfDom">
      <div class="contentWrapper">
        <div class="title">卓易开放平台合作伙伴付费服务协议</div>
        <br />
        <!-- <div>甲方：{{ detailData.invoice.invoice }}</div> -->
        <div>甲方：{{ detailData.userInfo.companyName }}</div>
        <!-- <div>地址：{{ detailData.postInfo.detailAddress }}</div> -->
        <div>地址：{{ detailData.invoice.taxAddress }}</div>
        <br />
        <div>乙方：上海卓悠网络科技有限公司</div>
        <div>地址：上海市徐汇区宜山路700号普天信息产业园A6栋</div>
        <br />
        <p>
          本协议是您（以下又称“合作伙伴”）与上海卓悠网络科技有限公司订立的具备法律效力的协议。本协议为您与卓悠已订立的《卓易开发者服务协议》的补充协议。您点击本协议下方的“我同意”按键（或类似按键）或使用本协议下任何服务即视为自您接受或使用之日（“生效日期”）起对本协议的充分理解和完全接受。如果您不同意本协议，您应当停止使用本协议下的服务。
        </p>
        <p>
          本协议未尽事宜，按照《卓易开发者服务协议》执行。如本协议条款与《卓易开发者服务协议》发生冲突的，仅在卓易开放平台合作伙伴付费服务的范围内，以本协议条款为准。
        </p>
        <div class="subTitle">1. 词语定义与解释</div>
        <p>
          1.1协议（或合同）：是指根据法律规定和协议当事人约定具有约束力的文件，构成协议的文件包括其附件、通用条款。
        </p>
        <p>
          1.2
          协议（或合同）当事人：是指甲方(您)和（或）乙方(上海卓悠网络科技有限公司)。
          <!-- 协议（或合同）当事人：是指甲方(上海卓悠网络科技有限公司)和（或）乙方(您)。 -->
        </p>
        <p>
          1.3
          甲方/您：指任何使用付费服务的开发者，并与乙方签订业务推广合作协议的当事人及取得该当事人资格的合法继承人。
        </p>
        <!-- <p>1.3 甲方：上海卓悠网络科技有限公司（以下简称“甲方”）</p> -->
        <p>
          1.4 乙方：上海卓悠网络科技有限公司（以下简称“乙方”）
          <!-- 乙方/您：指任何使用付费服务的开发者，并与甲方签订业务推广合作协议的当事人及取得该当事人资格的合法继承人。 -->
        </p>
        <p>1.5 双方：指甲方和乙方，亦称协议双方。</p>
        <p>
          1.6 预付费推广：
          甲方在推广前预先将推广服务费支付至乙方指定的银行账户中，通过卓易开放平台向最终用户提供（包括但不限于展示、分发、推广和开发）您的产品和/或服务。
          <!-- 乙方在推广前预先将推广服务费支付至甲方指定的银行账户中，通过卓易开放平台向最终用户提供（包括但不限于展示、分发、推广和开发）您的产品和/或服务。 -->
        </p>
        <p>
          1.7
          后付费推广：指您通过使用卓易开放平台，向最终用户提供（包括但不限于展示、分发、推广和开发）您的产品和/或服务，并通过后付费的方式向乙方支付推广费的行为。
          <!-- 后付费推广：指您通过使用卓易开放平台，向最终用户提供（包括但不限于展示、分发、推广和开发）您的产品和/或服务，并通过后付费的方式向甲方支付推广费的行为。 -->
        </p>
        <p>1.8 卓易开放平台推广的范围包括但不限于如下交易模式：</p>
        <p>CPD(Cost Per Download)：以用户每一次下载为基准结算推广费。</p>
        <p>CPA(Cost Per Action)：以用户每一次有效激活为基准结算推广费。</p>
        <p>CPT(Cost Per Time)：以广告发布时长为基准结算推广费。</p>
        <p>CPC(Cost Per Click)：以用户每次点击为基准结算推广费。</p>
        <p>CPM(Cost Per Mille)：以每一千次曝光量为基准结算推广费。</p>
        <p>CPI (Cost Per Installation)：以安装量计算数字营销费用的定价模型。</p>
        <p>
          CPS(Cost Per
          Sale)：以合作产品的可分配收入为基准，按照约定的比例结算推广费。
        </p>
        <p>
          1.9
          关联方：指一方现在或将来控制、受其控制或与其处于共同控制之下的任何公司、合法机构以及上述公司、合法机构的合法继承人。“控制”是指（1）直接或间接持有过半数的投票权；或（2）直接或间接享有过半数的可分配利润；或（3）直接或间接控制董事会半数以上成员的组成；或（4）直接或间接持有过半数的注册资本。
        </p>
        <div class="subTitle">2. 声明及保证</div>
        <p>
          2.1
          甲方保证：若甲方是独立法人、其他经济组织，甲方须是根据中华人民共和国相关法律合法设立并有效存续的独立法人、其他经济组织或同时完整地享有法定的民事权利能力和民事行为能力，足以行使本协议项下权利和履行其于本协议项下之义务。同时，甲方在履行本协议项下之义务时，行为不违反任何对甲方有约束力的适用法律的限制，也不会侵犯本协议以外任何第三方之合法权益
          甲方在卓易开放平台中提交及确认本协议的授权代表已获得充分授权来代表甲方签署本协议。若甲方是自然人，甲方本人具有完全民事权利能力和民事行为能力签署本协议。
        </p>
        <p>
          2.2
          甲方与乙方均拥有合法资质从事本协议项下之合作，且甲方从事合作之行为符合法律之规定。
          <!-- 乙方与甲方均拥有合法资质从事本协议项下之合作，且乙方从事合作之行为符合法律之规定。 -->
        </p>
        <p>
          2.3
          乙方保证是根据中华人民共和国相关法律合法设立并有效存续的独立法人，完整地享有法定的民事权利能力和民事行为能力，足以使其能行使本协议项下权利和履行其于本协议项下之义务。乙方在履行本协议项下之义务时，其行为不违反任何对其有约束力的适用法律的限制，也不会侵犯本协议以外任何第三方之合法权益。
          <!-- 甲方保证是根据中华人民共和国相关法律合法设立并有效存续的独立法人，完整地享有法定的民事权利能力和民事行为能力，足以使其能行使本协议项下权利和履行其于本协议项下之义务。甲方在履行本协议项下之义务时，其行为不违反任何对其有约束力的适用法律的限制，也不会侵犯本协议以外任何第三方之合法权益。 -->
        </p>
        <p>
          2.4
          甲方与乙方均保证依据各自商务状况和经营能力，完全履行本协议约定的各项义务。
          <!-- 乙方与甲方均保证依据各自商务状况和经营能力，完全履行本协议约定的各项义务。 -->
        </p>
        <div class="subTitle">3. 合作内容</div>
        <p>
          3.1
          甲方在乙方卓易开放平台中依据本协议的约定对甲方的产品进行推广，并向乙方支付推广费用。乙方依托乙方资源，在卓易开放平台设定的资源位上对甲方的产品进行推送、展示，吸引终端用户进行使用或下载。
          <!-- 乙方在甲方卓易开放平台中依据本协议的约定对乙方的产品进行推广，并向甲方支付推广费用。甲方依托甲方资源，在卓易开放平台设定的资源位上对乙方的产品进行推送、展示，吸引终端用户进行使用或下载。 -->
        </p>
        <p>
          3.2
          甲方应妥善保管开放平台账户及其密码，甲方应始终对其账号下的一切行为及结果负责，承担全部责任。
          <!-- 乙方应妥善保管开放平台账户及其密码，乙方应始终对其账号下的一切行为及结果负责，承担全部责任。 -->
        </p>
        <div class="subTitle">4. 协议有效期</div>
        <p>
          4.1本协议有效期限为 1 年，自{{
            dateTimeFilter(detailData.startTime)
          }}至{{ dateTimeFilter(detailData.endTime) }}止。
        </p>
        <p>4.2经双方协商一致，可以提前终止本协议。</p>
        <p>4.3如出现下列情形，本协议自动终止：</p>
        <p>
          （1）甲方解散、破产、重整、合并、分立、被收购、被注销等变更公司形式的；
        </p>
        <p>
          （2）甲方无法偿还到期债务，并且直接或间接地宣告破产，债权人已接管了其经营权或其主要资产；
        </p>
        <p>（3）甲方经营困难，已停业达30天以上；</p>
        <p>（4）甲方企业负责人失踪或恶意逃匿，导致企业无法继续经营的；</p>
        <p>
          （5）甲方发生重大违法行为，已被行政或司法机关查封其主要经营场所或责令停业的。
        </p>
        <p>（6）甲方不再具备接受本协议及履行本协议下义务的资质、权利或授权；</p>
        <p>
          （7）甲方有其它违法、违约、违反乙方平台规则的行为，经乙方要求改正，甲方拒不纠正的。
        </p>
        <p>4.4乙方因业务调整等原因，经书面通知甲方后可单方面终止本协议。</p>
        <p>
          4.5如甲方需要提前终止合作，应当提前15天以书面形式通知乙方，经双方协商一致后，双方可以提前终止本协议。
        </p>
        <p>
          4.6本协议的提前终止不影响双方于本协议提前终止日之前根据本协议已产生的权利和义务。
        </p>
        <p>4.7本协议的提前终止，不影响守约方向违约方请求赔偿的权利。</p>
        <div class="subTitle">5. 付费及支付要求</div>
        <p>双方确认，就本次推广合作，甲方选择对应的推广形式及结算方式</p>
        <p>5.1【预付费推广】</p>
        <p>
          5.1.1
          <!-- 双方确认，甲方以预付（充值）的形式预付推广服务费（亦称推广费或服务费），<div class="pageSplit"></div>乙方为甲方在“卓易开放平台”中开设预付账户，账户记载甲方预付款的充值记录、使用记录、余额等信息。 -->
          双方确认，甲方以预付（充值）的形式预付推广服务费（亦称推广费或服务费），<span
            class="pageSplit"
          ></span
          >乙方为甲方在“卓易开放平台”中开设预付账户，账户记载甲方预付款的充值记录、使用记录、余额等信息。
        </p>
        <p>
          5.1.2
          甲方每次预付（充值）时，甲方须将充值金额转账至乙方指定账户。甲方转账时，须以银行转账或以经乙方事先确认的其它方式，且款项只能转入乙方的对公账号，不得转入其它账号，否则引起的不利后果由甲方自行承担。
          <!-- 乙方每次预付（充值）时，乙方须将充值金额转账至甲方指定账户。乙方转账时，须以银行转账或以经甲方事先确认的其它方式，且款项只能转入甲方的对公账号，不得转入其它账号，否则引起的不利后果由乙方自行承担。 -->
        </p>
        <p>
          甲方完成转账之后，需在“卓易开放平台”上提交充值申请，并上传“打款凭证”（包括但不限于银行汇款单、网银水单及其复印件、扫描件、截图等任何可以有效证明甲方付款行为的材料），乙方将在确认收到甲方的充值款项后，同意甲方的充值申请并对甲方的预付账户余额进行更新。甲方可以登录甲方的注册账户对甲方的预付账户余额进行查询。
          <!-- 乙方完成转账之后，需在“卓易开放平台”上提交充值申请，并上传“打款凭证”（包括但不限于银行汇款单、网银水单及其复印件、扫描件、截图等任何可以有效证明乙方付款行为的材料），甲方将在确认收到乙方的充值款项后，同意乙方的充值申请并对乙方的预付账户余额进行更新。乙方可以登录乙方的注册账户对乙方的预付账户余额进行查询。 -->
        </p>
        <p>
          （首次推广时，建议先与我们的客服联系，确认甲方的产品符合推广要求，避免甲方充值后无法使用。）
          <!-- （首次推广时，建议先与我们的客服联系，确认乙方的产品符合推广要求，避免乙方充值后无法使用。） -->
        </p>
        <p>
          5.1.3
          为保障推广服务的持续进行，推广期间，甲方需保证预付账户中有足够的余额；甲方的预付推广费用在协议推广期间余额不足的，乙方系统将自动暂停推广；在甲方续费之后乙方将实时开启服务。如推广合作结束后，甲方预付推广费仍有剩余的，甲方可向乙方申请退款。
          <!-- 为保障推广服务的持续进行，推广期间，乙方需保证预付账户中有足够的余额；乙方的预付推广费用在协议推广期间余额不足的，甲方系统将自动暂停推广；在乙方续费之后甲方将实时开启服务。如推广合作结束后，乙方预付推广费仍有剩余的，乙方可向甲方申请退款。 -->
        </p>
        <p>
          5.1.4
          乙方根据甲方的推广费实际发生额，从甲方的预付（充值）额中进行相应扣除。如需开具发票，甲方需在“开发者后台”中进行申请，申请发票前，请检查甲方的财务信息（即开票信息），如因甲方写错误的开票信息将不再重新开票。
        </p>
        <p>
          5.1.5 双方确认，甲方采用如下计费方式向乙方支付服务费，费用计算方式为:
          CPD、CPC、CPM。
        </p>
        <p>
          5.1.6
          双方以一个自然月为统计周期进行对账，自甲方的产品开始推广之日起，甲方可以查询预付（充值）账户金额，双方每月10日前完成上个月的预付费金额的对账并由乙方向甲方开具等额的增值税专用发票。如甲方对结算数据存在异议的，应在收到乙方提供的结算数据后5个工作日内提出异议。若甲方已在结算单上盖章，则视为对结算数据无异议，不得对结算单确认数据再次提出异议。
        </p>
        <p>
          5.1.7
          合作期限内，甲乙双方按照国家税法的规定各自独立缴纳因此而产生的相关税费。
        </p>
        <p>5.2【后付费推广】</p>
        <p>
          5.2.1
          <!-- 甲乙双方一致同意采取每自然月结算的方式，即双方每月10日前核对上个月的结算数据及结算费用，并由乙方向甲方开具等额的增值税专用发票，甲方收到乙方开具的税率为6%的增值税专用发票后于10个工作日内将双方确认的结算金额以银行转账方式支付至乙 <div class="pageSplit"></div>方指定银行账户。 -->
          甲乙双方一致同意采取每自然月结算的方式，即双方每月10日前核对上个月的结算数据及结算费用，并由乙方向甲方开具等额的增值税专用发票，甲方收到乙方开具的税率为6%的增值税专用发票后于10个工作日内将双方确认的结算金额以银行转账方式支付至乙
          <span class="pageSplit"></span>方指定银行账户。
        </p>
        <!-- <div class="pageSplit"></div> -->
        <p>
          5.2.2
          如甲方对结算数据存在异议的，应在收到乙方提供的结算数据后5个工作日内提出异议。若甲方已在结算单上盖章，则视为对结算数据无异议，不得对结算单确认数据再次提出异议。
          <!-- 如乙方对结算数据存在异议的，应在收到甲方提供的结算数据后5个工作日内提出异议。若乙方已在结算单上盖章，则视为对结算数据无异议，不得对结算单确认数据再次提出异议。 -->
        </p>
        <p>
          5.2.3
          甲方应在合同约定的时间，按照双方确认的《信息服务单》向乙方支付推广费。
          <!-- 乙方应在合同约定的时间，按照双方确认的《信息服务单》向甲方支付推广费。 -->
        </p>
        <p>
          5.2.4
          合作期限内，甲乙双方按照国家税法的规定各自独立缴纳因此而产生的相关税费。
        </p>
        <p>5.2.5 乙方开户银行名称和账号为：</p>
        <p>开户名称：上海卓悠网络科技有限公司</p>
        <p>开户银行：中国银行股份有限公司上海市漕河泾支行</p>
        <p>公司账号：444263840606</p>
        <p>5.2.6 甲方开票信息为：</p>
        <p>开票内容：信息服务费</p>
        <p>
          发票类型：<span v-if="detailData.invoice.taxNature == 1"
            >增值税专用发票</span
          ><span v-else>增值税普通发票</span>
        </p>
        <p>地址：{{ detailData.invoice.taxAddress }}</p>
        <p>电话：{{ detailData.invoice.registerTel }}</p>
        <p>纳税人识别号：{{ detailData.invoice.taxId }}</p>
        <p>户名：{{ detailData.invoice.invoice }}</p>
        <p>开户行：{{ detailData.invoice.bank }}</p>
        <p>账号：{{ detailData.invoice.bankAccount }}</p>
        <div class="subTitle">6. 双方权利和义务</div>
        <p>
          6.1甲方负责甲方产品的开发并向乙方提供推广素材，甲方有责任确保硬件的有效运行和软件的不断开发、更新完善，以避免技术性投诉（属移动网络的技术原因除外）。如遇特殊情况，甲方需要通过电子邮件或电话向乙方说明原因。
          <!-- 6.1乙方负责乙方产品的开发并向甲方提供推广素材，乙方有责任确保硬件的有效运行和软件的不断开发、更新完善，以避免技术性投诉（属移动网络的技术原因除外）。如遇特殊情况，乙方需要通过电子邮件或电话向甲方说明原因。 -->
        </p>
        <p>
          6.2甲方负责受理用户对产品技术、功能实现的投诉、寻求帮助等其他问题，乙方应积极配合甲方处理用户的有关咨询、申告和投诉。
          <!-- 6.2乙方负责受理用户对产品技术、功能实现的投诉、寻求帮助等其他问题，甲方应积极配合乙方处理用户的有关咨询、申告和投诉。 -->
        </p>
        <p>
          <!-- 6.3甲方负责本协议项下产品及产品内容的合法性、真实性、完整性且不侵犯任何第三方的知识产权。乙方有权对甲方提供的产品及产品内容进行审核，乙方审核通过不视为乙方对甲方所提供内容的合法性、真实性、完整性进行保证。因侵犯知识产权、肖像权等违法违规行为引起的一切责任由甲方承担、与乙方无涉。乙方自接到被侵权人通知之日起，有权停止<div class="pageSplit"></div>产品推广服务，包括但不限于停止预装、内置甲方产品或下架甲方产品；若因此造成乙方被诉或者提起诉讼的，乙方有权终止本协议并要求甲方就乙方的损失承担赔偿责任，损失包括但不限于律师费、诉讼费、公证费、财产保全费、鉴定费、调查费、执行费、差旅费、赔偿金（权利人或者用户）、行政罚款等。 -->
          6.3甲方负责本协议项下产品及产品内容的合法性、真实性、完整性且不侵犯任何第三方的知识产权。乙方有权对甲方提供的产品及产品内容进行审核，乙方审核通过不视为乙方对甲方所提供内容的合法性、真实性、完整性进行保证。因侵犯知识产权、肖像权等违法违规行为引起的一切责任由甲方承担、与乙方无涉。乙方自接到被侵权人通知之日起，有权停止<span
            class="pageSplit"
          ></span
          >产品推广服务，包括但不限于停止预装、内置甲方产品或下架甲方产品；若因此造成乙方被诉或者提起诉讼的，乙方有权终止本协议并要求甲方就乙方的损失承担赔偿责任，损失包括但不限于律师费、诉讼费、公证费、财产保全费、鉴定费、调查费、执行费、差旅费、赔偿金（权利人或者用户）、行政罚款等。
        </p>
        <p>
          6.4甲方产品为游戏的，应向相关部门进行游戏出版申报，并取得游戏出版物号和版号。甲方应保证游戏产品内容完整性，须在游戏开始前、《健康游戏忠告》后，设置专门页面，标明游戏著作权人、出版服务单位、批准文号、出版物号等信息，并严格按照已批准的内容出版运营。甲方负责审核并记录游戏日常更新，不得擅自添加不良内容。因违反以上约定而产生的一切责任及后果，由甲方自行承担；如因此造成乙方或者用户损失的，甲方应承担全部赔偿责任。
          <!-- 6.4乙方产品为游戏的，应向相关部门进行游戏出版申报，并取得游戏出版物号和版号。乙方应保证游戏产品内容完整性，须在游戏开始前、《健康游戏忠告》后，设置专门页面，标明游戏著作权人、出版服务单位、批准文号、出版物号等信息，并严格按照已批准的内容出版运营。乙方负责审核并记录游戏日常更新，不得擅自添加不良内容。因违反以上约定而产生的一切责任及后果，由乙方自行承担；如因此造成甲方或者用户损失的，乙方应承担全部赔偿责任。 -->
        </p>
        <p>
          6.5若甲方根据业务的发展情况，需要乙方调整相关推广服务的，应提前至少5个工作日以书面方式告知乙方，双方协商一致后签订书面补充协议，以供乙方遵守和执行。
          <!-- 6.5若乙方根据业务的发展情况，需要甲方调整相关推广服务的，应提前至少5个工作日以书面方式告知甲方，双方协商一致后签订书面补充协议，以供甲方遵守和执行。 -->
        </p>
        <p>
          6.6乙方保证拥有合法的资质及授权推广本协议约定的甲方产品和提供相应的服务，其履行本协议项下的权利义务不违反任何对其有约束力的适用法律，否则由此引发的纠纷及法律责任，由乙方自行承担，因此导致甲方损失的，乙方应全部赔偿。
          <!-- 6.6甲方保证拥有合法的资质及授权推广本协议约定的乙方产品和提供相应的服务，其履行本协议项下的权利义务不违反任何对其有约束力的适用法律，否则由此引发的纠纷及法律责任，由甲方自行承担，因此导致乙方损失的，甲方应全部赔偿。 -->
        </p>
        <p>
          6.7乙方保证其推广渠道和下载安装的合法性、合规性，并承担由此产生的市场推广、宣传、广告等相关费用。
          <!-- 6.7甲方保证其推广渠道和下载安装的合法性、合规性，并承担由此产生的市场推广、宣传、广告等相关费用。 -->
        </p>
        <p>
          6.8在乙方推广甲方产品前，甲方有权了解乙方的推广渠道，且有权对乙方的推广渠道进行审查并提出相关合理性意见。
          <!-- 6.8在甲方推广乙方产品前，乙方有权了解甲方的推广渠道，且有权对甲方的推广渠道进行审查并提出相关合理性意见。 -->
        </p>
        <p>
          6.9在本合作协议有效期内，乙方负责在其推广渠道对本协议约定的甲方产品进行宣传推广。
          <!-- 6.9在本合作协议有效期内，甲方负责在其推广渠道对本协议约定的乙方产品进行宣传推广。 -->
        </p>
        <p>
          6.10乙方保证其推广的甲方产品仅在乙方平台或/和具有代理权的推广渠道推广，不得在未经甲方许可的平台上为甲方进行推广。
          <!-- 6.10甲方保证其推广的乙方产品仅在甲方平台或/和具有代理权的推广渠道推广，不得在未经乙方许可的平台上为乙方进行推广。 -->
        </p>
        <p>
          6.11乙方在推广宣传的过程中，应严格按照相关法律法规及运营商的要求进行推广，乙方不得擅自将甲方产品的任何程序文件进行任何的技术改动及反向设计，不得擅自修改甲方提供的产品名称、产品类别、产品介绍、推广语、产品截图等信息，不得使用无关内容误导用户下载甲方产品，不得在色情，赌博等非法网站、频道或页面上进行推广，不得使用非法手段侵犯用户的合法权益。如果由于乙方的原因导致客户服务投诉或引发法律责任，乙方将承担由此产生的一切责任及后果，并对甲方及甲方指定执行本协议相关公司的损失予以赔偿（包括但不限于运营商不予结算、经济处罚的损失）。
          <!-- 6.11甲方在推广宣传的过程中，应严格按照相关法律法规及运营商的要求进行推广，甲方不得擅自将乙方产品的任何程序文件进行任何的技术改动及反向设计，不得擅自修改乙方提供的产品名称、产品类别、产品介绍、推广语、产品截图等信息，不得使用无关内容误导用户下载乙方产品，不得在色情，赌博等非法网站、频道或页面上进行推广，不得使用非法手段侵犯用户的合法权益。如果由于甲方的原因导致客户服务投诉或引发法律责任，甲方将承担由此产生的一切责任及后果，并对乙方及乙方指定执行本协议相关公司的损失予以赔偿（包括但不限于运营商不予结算、经济处罚的损失）。 -->
        </p>
        <div class="pageSplit"></div>
        <p>
          6.12乙方保证不对甲方产品进行反向工程、反向编译或反汇编，不得破坏其完整性（包括程序代码、数据等），不得删除甲方产品中有关甲方版权的说明或申明等信息，且不对甲方产品的数据进行任何形式的抓取和收集。
          <!-- 6.12甲方保证不对乙方产品进行反向工程、反向编译或反汇编，不得破坏其完整性（包
         <div class="pageSplit"></div>
          括程序代码、数据等），不得删除乙方产品中有关乙方版权的说明或申明等信息，且不对乙方产品的数据进行任何形式的抓取和收集。 -->
        </p>
        <p>6.13甲方按照本协议约定及时、足额向乙方支付结算费用。</p>
        <!-- <p> 6.13乙方按照本协议约定及时、足额向甲方支付结算费用。</p> -->
        <!-- <p>
          6.14甲乙双方不得私自向对方工作人员给付各种名义的回扣（包括金钱或实物）。若任一方违反本条款，守约方有权立即解除本协议。若该行为给守约方或其关联公司造成任何损失的，违约方应当予以赔偿。本条款在本协议终止后仍然有效。 -->
        <!-- 6.14甲乙双方不得私自向对方工作人员给付各种名义的回扣（包括金钱或实物）。若任一方违反本条款，守约方有权立即解除本协议。若该行为给守约方或其关联公司造成任何损失的，违约方应当予以赔偿。本条款在本协议终止后仍然有效。 -->
        <!-- </p> -->
        <div class="subTitle">7. 廉洁保证条款</div>
        <p>
          为了保护合作双方的合法权益，确保双方的业务往来符合诚信和公平交易等原则，着眼于双方建立长期友好的商业伙伴关系，促进双方关系良好发展，经双方友好协商，达成以下协议内容
        </p>
        <p>
          7.1
          本条所指的商业贿赂是指一方为获取与另一方的合作及合作的利益，一方或其单位工作人员给予另一方员工的一切物质及精神上的直接或间接的不正当利益。
        </p>
        <p>
          7.2不正当利益：一方或其工作人员不得以公司或个人名义向另一方任何员工及关联人员提供、给予（赠予或非公允价值给予）合作业务范围外的直接或间接的任何利益，包括但不限于：明扣、暗扣、现金、购物卡、实物、有价证券、旅游、股份、红利、礼
          金、礼品、娱乐活动票券，特别折扣或样品，一方支付的旅行、餐饮、娱乐、合作业务衍生利益或其他物质利益和非物质性利益。
        </p>
        <p>
          7.3利益冲突：包括但不限于（1）一方不得向另一方员工及其关联人员提供任何形式的借款；（2）一方的股东、监事、经理、高级管理人员、合作项目负责人及项目成员系另一方员工或其关联人员的，应在合作前以书面方式如实、全面告知。（3）合作过程中，一方不得允许另一方员工及其配偶持有或由第三方代持有其股权（但通过公开的证券交易市场且低于发行在外5%的权益、通过直接或间接持有无实际控制权的基金、
          或通过受益人非本人或关联人员的信托方式持有的股份除外）。
        </p>
        <p>7.4若一方违反上述约定行为之一，另一方有权单方终止本合同。</p>
        <p>
          7.5如果在业务合作中发现任何违反或者试图违反反商业贿赂、反贪污、反腐败的法律法规的行为，双方均可举报。对于任何举报行为和举报者，双方予以保密。
        </p>
        <p>举报邮箱:clean@droi.com</p>
        <div class="subTitle">8. 违约责任</div>
        <p>8.1甲方和乙方双方应正当行使权利，履行义务，保证本协议的顺利履行。</p>
        <p>
          8.2任何一方没有充分、及时履行义务，或违反本协议项下保证或承诺的，均视为违约。
        </p>
        <!-- <p>
          8.3任何一方在知悉对方违约时，均可采取向违约方发出违约通知的形式，要求违约方采
          <div class="pageSplit"></div>取有效合理的补救措施纠正其违约行为并消除影响。如违约方七天内未纠正其违约行为，则守约方可以立即单方解除本协议。违约方采取补救措施并不妨碍守约方要求违约方赔偿其因此所遭受的所有损失。
        </p> -->
        <p>
          8.3任何一方在知悉对方违约时，均可采取向违约方发出违约通知的形式，要求违约方采
          <span class="pageSplit"></span
          >取有效合理的补救措施纠正其违约行为并消除影响。如违约方七天内未纠正其违约行为，则守约方可以立即单方解除本协议。违约方采取补救措施并不妨碍守约方要求违约方赔偿其因此所遭受的所有损失。
        </p>
        <p>8.4本协议其他条款对违约责任有明确约定的，从其约定。</p>
        <div class="subTitle">9. 不可抗力</div>
        <p>
          9.1
          “不可抗力”是指本协议双方不能控制、不可预见并不可避免的客观事件，该事件妨碍、影响或延误任何一方根据本协议履行其全部或部分义务。不可抗力包括但不限于政府行为、自然灾害、战争或其他类似事件等。
        </p>
        <p>
          9.1.1.鉴于互联网所具有之特殊性质，乙方的免责事由亦包括但不限于下列任何影响网络正常经营之不可抗力情形：
          <!-- 8.1.1.鉴于互联网所具有之特殊性质，甲方的免责事由亦包括但不限于下列任何影响网络正常经营之不可抗力情形： -->
        </p>
        <p>(1) 黑客攻击、计算机病毒侵入或发作；</p>
        <p>
          (2)
          计算机系统遭到破坏、瘫痪或无法正常使用而导致信息或纪录的丢失、乙方不能履行本协议下义务；
        </p>
        <p>(3)电信部门技术调整导致之重大影响；</p>
        <p>(4)因政府管制而造成的暂时性关闭等。</p>
        <p>
          9.2任何一方因受不可抗力事件影响不能部分或全部履行协议的，无需承担责任，但因此导致合作提前终止的，应返还对方由此预付的费用。
        </p>
        <div class="pageSplit"></div>
        <p>
          9.3出现不可抗力事件时，受不可抗力影响的一方应在不可抗力发生后合理可行的最短时间内书面通知对方，并告知对方不可抗力事件对本协议可能产生的影响，而且应当在不可抗力事件发生后15日内向对方提供不可抗力发生地的政府、公证机关或商会出具的证明或权威媒体的报道。因怠于通知造成对方损失的，负有通知义务的一方应对对方由此遭受的损失进行赔偿。
        </p>
        <p>
          9.4不可抗力事件影响解除后，双方可协商继续履行本协议。如不可抗力事件持续超过30日的，双方应协商确定延长履行期限或提前解除本协议。
        </p>
        <div class="subTitle">10. 保密义务</div>
        <p>
          10.1
          未经对方书面许可，任何一方不得向第三方（经有关法律、法规、政府部门、证券交易所或其他监管机构要求和甲乙双方的法律、会计、商业及其他顾问、授权雇员除外）泄露本协议的任何内容、本协议的签订及履行情况，以及通过签订和履行本协议而获知的对方及对方关联公司的任何信息（包括但不限于公司组织架构、产品推广价格、用户资料等业务、财务信息）；否则，守约方有权取消合作，并要求违约方赔偿守约方因此遭受的全部损失。
        </p>
        <p>10.2 本保密条款的法律效力不因本协议的终止而终止。</p>
        <div class="subTitle">11. 争议解决与法律适用</div>
        <p>
          11.1
          对于因本协议的解释及执行而产生之争议，应首先由双方通过友好协商解决。
        </p>
        <p>
          11.2
          如争议未能以前述方式解决，则任何一方均可向上海市徐汇区有管辖权的人民法院提起诉讼。
        </p>
        <p>
          11.3
          本协议的订立、生效、履行、终止、解释及争议解决等均适用中华人民共和国法律（为免疑义，不包括香港、澳门及台湾地区）。
        </p>
        <div class="subTitle">12. 附则</div>
        <p>
          12.1
          本协议内容包括协议正文及所有与卓易开放平台相关的已经发布的或将来可能发布的各类卓易开放平台规则。上述内容为本协议不可分割的一部分，与本协议正文具有同等法律效力。甲方在使用卓易开放平台服务时，应予遵守。
          <!-- 本协议内容包括协议正文及所有与卓易开放平台相关的已经发布的或将来可能发布的各类卓易开放平台规则。上述内容为本协议不可分割的一部分，与本协议正文具有同等法律效力。乙方在使用卓易开放平台服务时，应予遵守。 -->
        </p>
        <p>
          12.2
          本协议以数据电文形式签订，自甲方在卓易开放平台点击“同意”或“下一步”（具体措辞以服务开通时显示的为准）后生效。
          <!-- 本协议以数据电文形式签订，自乙方在卓易开放平台点击“同意”或“下一步”（具体措辞以服务开通时显示的为准）后生效。 -->
        </p>
        <div class="pageSplit"></div>
        <p>
          12.3
          如本协议的任何条款被视作无效或无法执行，则除上述条款可被分离外，其余部分条款则仍具有法律效力。
        </p>
        <div class="footer">
          <div>
            <p>甲方：{{ detailData.userInfo.companyName }}</p>
            <p>（盖章）</p>
            <p>{{ dateTimeFilter(detailData.startTime) }}</p>
          </div>
          <div>
            <p>乙方：上海卓悠网络科技有限公司</p>
            <p>（盖章）</p>
            <p>{{ dateTimeFilter(detailData.startTime) }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
export default {
  props: {
    detailData: {
      type: Object,
      default() {
        return {
          postInfo: {},
          invoice: {},
          userInfo: {},
        };
      },
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    dateTimeFilter(string) {
      if (string) {
        let Y = string.split("-");
        return `${Y[0]} 年 ${Y[1]} 月 ${Y[2]} 日`;
      } else {
        return " ";
      }
    },
    // 合同内容下载为pdf
    exportPdf() {
      this.loading = true;
      // target 为需要导出PDF的DOM元素
      const targetDom = document.querySelector("#pdfDom"); // 这个dom元素是要导出pdf的div容器
      var clonedNode = targetDom.cloneNode(true); //克隆元素解决滚动区域问题
      clonedNode.setAttribute(
        "style",
        `width: ${targetDom.width};height: ${targetDom.scrollHeight}px;`
      ); // 克隆元素的高度设置为可滚动的高度
      document.body.appendChild(clonedNode); //放到body后面
      html2canvas(clonedNode, {
        allowTaint: true,
        useCORS: true, // 如果说所生成的页面中带有跨域的图片，这个useCors需要设置为True 否则画布被污染不会显示
        backgroundColor: "#fff",
        scale: 2,
      }).then((canvas) => {
        const pdf = new jsPDF("p", "mm", "a4"); // A4纸，纵向
        let ctx = canvas.getContext("2d"),
          a4w = 170,
          a4h = 257, // A4大小，210mm x 297mm，保留左右20mm边距 上下20mm的边距，显示区域170x257
          imgHeight = Math.floor(a4h * (canvas.width / a4w)), //按A4显示比例换算一页图像的像素高度
          // 已生成pdf的html页面高度
          renderedHeight = 0;

        while (renderedHeight < canvas.height) {
          const page = document.createElement("canvas");
          const pageHeight = Math.min(
            imgHeight,
            canvas.height - renderedHeight
          ); //可能内容不足一页
          page.width = canvas.width;
          page.height = pageHeight;
          //用getImageData剪裁指定区域，并画到前面创建的canvas对象中
          page
            .getContext("2d")
            .putImageData(
              ctx.getImageData(0, renderedHeight, canvas.width, pageHeight),
              0,
              0
            );
          pdf.addImage(
            page.toDataURL("image/jpeg", 1.0),
            "JPEG",
            20,
            20,
            a4w,
            Math.min(a4h, (a4w * page.height) / page.width)
          ); // 添加图像到页面，保留左右20mm边距 上下30mm
          renderedHeight += imgHeight;
          if (renderedHeight < canvas.height) pdf.addPage(); //如果后面还有内容，添加一个空页
        }
        const today = new Date().toLocaleDateString().replace(/\//g, "-");
        // 本地下载
        pdf.save(`产品推广合作协议${today}.pdf`, pdf);
        document.body.removeChild(clonedNode); //最后记得移除截图时添加的
        this.loading = false;
      });
    },
  },
};
</script>
<style scoped lang="scss">
.pageSplit {
  margin-bottom: 20px;
  display: block;
}

div {
  font-weight: inherit;
}
#pdfDom {
  /* 高度设置为0,使下载内容不可见隐藏 */
  height: 0px;
  width: 650px;
  overflow: auto;
  text-align: left;
}
.contentWrapper {
  font-size: 16px;
  line-height: 2;
  .title {
    font-size: 22px;
    font-weight: 700;
    text-align: center;
  }
  .subTitle {
    font-size: 18px;
    font-weight: 700;
    margin: 15px 0px;
  }
  p {
    text-indent: 2em;
  }
  .footer {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    & > div {
      width: 50%;
    }
  }
}
</style>
